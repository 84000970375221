:root {
  --clr-primary-000: #ffffff;
  --clr-primary-100: #b0f5f6ae;
  --clr-primary-150: #00b3b648;
  --clr-primary-200: #00b4b6;
  --clr-primary-400: #006883;
  --clr-primary-900: #808080;
  --clr-primary-850: #808080bb;
  --clr-error: #d62626;

  --clr-navbar: var(--clr-primary-400);
  --clr-navbar-hover: var(--clr-primary-400);

  // Look up how to import fonts
  // Gothic
  --ff-accent: "Mulish", sans-serif;
  // Calabri
  --ff-primary: "Open Sans", sans-serif;

  --ff-body: var(--ff-primary);
  --ff-title: var(--ff-accent);

  --fw-regular: 300;
  --fw-semi-bold: 500;
  --fw-bold: 700;

  --letter-spacing: 0.2em;

  --padding: 1rem;
  --padding-2: calc(2 * var(--padding));
  --padding-3: calc(3 * var(--padding));

  --fs-init: 0.625rem;

  --fs-300: 1.6rem;
  --fs-400: 1.8rem;
  --fs-500: 2.5rem;

  // experimental clamp transitions
  // --fs-200: clamp(1.2rem, -3.3rem + 6vw, 1.5rem);
  // --fs-250: clamp(var(--fs-200), 2.5vw, var(--fs-body));
  // --fs-300: clamp(1.6rem, -1.4rem + 4vw, 1.8rem);
  // --fs-400: clamp(1.8rem, -5.7rem + 10vw, 2.3rem);
  // --fs-450: clamp(2.1rem, -5.4rem + 10vw, 2.6rem);
  // --fs-500: clamp(2.5rem, -6.5rem + 12vw, 3.1rem);
  // --fs-500: clamp(2.7rem, -6.5rem + 12.2vw, 3.3rem);
  // --fs-600: clamp(3rem, -9rem + 16vw, 3.8rem);
  // --fs-700: clamp(3.5rem, -10rem + 18vw, 4.4rem);
  // --fs-800: clamp(5rem, -14.5rem + 26vw, 6.3rem);
  // --fs-900: clamp(9rem, -25.5rem + 46vw, 11.3rem);

  --fs-button: var(--fs-300);
  --fs-body: var(--fs-400);
  --fs-title: var(--fs-500);

  --NavDynamicHeight: 0;
}
// MediaQueries
$media-250: 30em;
$media-300: 35em;
$media-350: 40.25em;
// $media-400: 50em;
$media-600: 60em;
$media-800: 84em;

//Reset
body,
html {
  // height: 100%;
  background: var(--clr-primary-000);
  // font-size: 62.5%;
  // font-size: 0.625rem;
  font-size: var(--fs-init);
  min-height: -webkit-fill-available;
  /* mobile viewport bug fix */
  min-height: 100vh;
}

::before,
::after {
  box-sizing: border-box;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

.App {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  /* mobile viewport bug fix */
  min-height: 100dvh;

  display: flex;
  flex-direction: column;
}

img,
picture,
svg {
  max-width: 100%;
  display: block;
}

a:hover,
a:visited,
a:link,
a:active,
a {
  // all: unset;
  text-decoration: none;
  color: var(--clr-primary-000);
  cursor: pointer;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@media (min-width: 50em) and (max-width: 64em) {
  :root {
    --fs-300: 1.8rem;
    // --fs-400: 2.3rem;
    --fs-500: 3.1rem;

    // --fs-200: clamp(1.5rem, -0.448rem + 2vw, 1.6rem);
    // --fs-300: clamp(1.8rem, -5.992rem + 8vw, 2.2rem);
    // --fs-400: clamp(2.3rem, 0.352rem + 2vw, 2.4rem);
    // --fs-450: clamp(2.6rem, -1.296rem + 4vw, 2.8rem);
    // --fs-500: clamp(3.1rem, -2.744rem + 6vw, 3.4rem);
    // --fs-600: clamp(3.8rem, -2.044rem + 6vw, 4.1rem);
    // --fs-700: clamp(4.4rem, -1.444rem + 6vw, 4.7rem);
    // --fs-800: clamp(6.3rem, -3.44rem + 10vw, 6.8rem);
    // --fs-900: clamp(11.3rem, -6.232rem + 18vw, 12.2rem);
  }
}

@media (min-width: 64em) {
  :root {
    --fs-300: 2.2rem;
    // --fs-400: 2.4rem;
    --fs-500: 3.4rem;
  }
}

// General Styling
.title {
  font-family: var(--ff-title);
  font-size: var(--fs-title);
  color: var(--clr-primary-400);

  display: flex;
  justify-content: center;

  padding-block: var(--padding-2);
}

.title-style {
  font-family: var(--ff-title);
  color: var(--clr-primary-400);
  font-size: var(--fs-body);
}

.title-white {
  font-family: var(--ff-title);
  color: var(--clr-primary-000);
  font-size: var(--fs-title);
}

.body {
  font-family: var(--ff-body);
  color: var(--clr-primary-900);
  max-width: 55ch;
  font-size: var(--fs-body);
}
.body-style {
  font-family: var(--ff-body);
  color: var(--clr-primary-900);
  font-size: var(--fs-body);
}
.body-white {
  color: var(--clr-primary-000);
  font-family: var(--ff-body);
  font-size: var(--fs-body);
  max-width: 55ch;
}
.body-blue {
  color: var(--clr-primary-200);
  font-family: var(--ff-body);
  font-size: var(--fs-body);
  max-width: 55ch;
}
.text-aligned-centered {
  text-align: center;
}
.align-left {
  align-self: left
}

.anchor {
  font-family: var(--ff-body);
  color: var(--clr-primary-000);
  font-size: var(--fs-body);
}

.self-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.pad-inline {
  padding-inline: var(--padding-3);
}
.pad-block {
  padding-block: var(--padding-2);
}
.pad-bottom {
  padding-bottom: var(--padding-2);
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
}
.white-on-grey {
  color: var(--clr-primary-000);
  background-color: var(--clr-primary-900);
}

.white-on-blue {
  color: var(--clr-primary-000);
  background-color: var(--clr-primary-200);
}

.white-on-dark-blue {
  color: var(--clr-primary-000);
  background-color: var(--clr-primary-400);
}

.white-bg {
  background-color: var(--clr-primary-000);
}
.dark-blue-bg {
  background-color: var(--clr-primary-400);
}

.fs-button {
  font-size: var(--fs-button);
}


.full-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.flex-screen {
  display: flex;
  flex-direction: column;
}

.mid-screen {
  height: 50vh;
  display: flex;
  flex-direction: column;
}
