.FormContainer {
  // display
  display: grid;
  grid-template-rows:
    repeat(2, minmax(0, 1fr))
    auto
    repeat(3, minmax(0, 1fr))
    auto
    minmax(0, 1fr)
    auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5em;
  column-gap: 1em;
  grid-template-areas:
    "FormName FormName"
    "FormEmail FormEmail"
    "error error"
    "FormCompany FormCompany"
    "FormBudget FormBudget"
    "FormStart FormEnd"
    "FormMessage FormMessage"
    "FormQuestion FormQuestion"
    "FormButton FormButton";
  // positioning
  position: relative;
  z-index: 10;
  // box-model
  --form-padding: 1.8rem;
  padding: var(--form-padding);
  padding-top: 0;
}

.FormContainerMinimized {
  // display
  display: grid;
  grid-template-rows:
    repeat(2, minmax(0, 1fr))
    auto
    repeat(2, minmax(0, 1fr))
    auto
    minmax(0, 1fr)
    auto;
  grid-template-columns: minmax(0, 1fr);
  gap: 0.5em;
  grid-template-areas:
    "FormName"
    "FormEmail"
    "error"
    "FormPhone"
    "FormCompany"
    "FormMessage"
    "FormQuestion"
    "FormButton";
  // positioning
  position: relative;
  z-index: 10;
  // box-model
  --form-padding: 1.8rem;
  padding: var(--form-padding);
  padding-top: 0;
  margin-top: 1.25em;
}

.FormInput {
  // box-model
  padding-inline: 1rem;
  background-color: var(--clr-primary-000);
  width: 100%;
  width: calc(100% - var(--form-padding));
  height: 3.25em;
  border: none;
  // type
  font-family: var(--ff-body);
  font-weight: var(--fw-skinny);
  color: var(--clr-primary-900);
  border: none;
  // misc
  border-radius: 0.6rem;
}

.FormInputContainer {
  // display
  display: grid;
  grid-template-rows: minmax(0, 2em) auto;
  grid-template-areas:
    "formLabel"
    "input";
  // box-model
  margin-bottom: 1em;

  // grid-area
  &.FormLabel {
    grid-area: formLabel;
  }
  &.FormInput {
    grid-area: input;
  }

  &.FormInputName {
    grid-area: FormName;
  }
  &.FormInputEmail {
    grid-area: FormEmail;
  }
  &.FormInputPhone {
    grid-area: FormPhone;
    margin-top: -0.25em;
  }
  &.FormInputCompany {
    grid-area: FormCompany;
    //needed under the error
    // margin-top: -0.25em;
  }
  &.FormInputBudget {
    grid-area: FormBudget;
  }
  &.FormInputQuestion {
    grid-area: FormQuestion;
  }
  &.FormInputMessage {
    grid-area: FormMessage;
  }
  &.FormInputStart {
    grid-area: FormStart;
  }
  &.FormInputEnd {
    grid-area: FormEnd;
  }
}
.FormMessage.FormInput {
  // box-model
  padding-block: 1rem;
  // misc
  resize: vertical;
}
.FormQuestion.FormInput {
  // box-model
  width: 100%;
}

.FormButtonContainer {
  // display
  display: grid;
  justify-content: center;
  align-items: center;
  // grid-area
  grid-area: FormButton;
}

.FormButton {
  // display
  display: flex;
  justify-self: center;
  justify-content: center;
  // box-model
  height: 2.5em;
  width: 10em;
  margin-inline: auto;
  background-color: var(--clr-primary-400);
  // type
  font-family: var(--ff-body);
  color: var(--clr-primary-000);
  border: none;
  // misc
  border-radius: 0.5em;
}

.FormLabel {
  // display
  align-self: flex-start;
  // box-model
  padding-bottom: 0.4rem;
}

.FormStart.FormInput,
.FormEnd.FormInput {
  // box-model
  padding-inline: 0;
  width: 100%;
  // type
  text-align: center;
  // misc
  resize: vertical;
}
.FormInputContainer.FormInputStart {
  // display
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.react-datepicker__input-container {
  // display
  justify-content: center;
}
.react-datepicker {
  // display
  display: inline-block;
  // positioning
  position: relative;
  // box-model
  border: 0px;
  background-color: var(--clr-primary-200);
  // type
  font-family: var(--ff-body);
  // misc
  border-radius: 0;
}

.react-datepicker__header {
  // positioning
  position: relative;
  // box-model
  padding-top: 8px;
  border-bottom: 0px;
  background-color: var(--clr-primary-200);
  // type
  text-align: center;
  // misc
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.react-datepicker__today-button {
  // box-model
  background-color: var(--clr-primary-200);
  border-top: 0px;
  padding: 5px 0;
  clear: left;
  // type
  text-align: center;
  font-weight: var(--fw-bold);
  // misc
  cursor: pointer;
}

.FormErrorContainer {
  // grid-area
  grid-area: error;
  // box-model
  align-self: center;
}

.FormError {
  // display
  display: flex;
  justify-content: center;
  align-items: center;
  // box-model
  height: 1em;
  padding: 0.25em;
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline: auto;
  // type
  line-height: 0.9;
  letter-spacing: 0.182em;
  font-weight: 600;
  font-style: oblique;
  color: var(--clr-error);
  font-family: var(--ff-body);
  font-size: var(--fs-body);
  // misc
  border-radius: 1rem;
}

::placeholder {
  // type
  opacity: 0.8;
}
